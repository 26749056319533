import React from "react"
import { Routing, getId, getTitle } from "../utils/routing"
import Layout from "../components/layout"
import Group from "../components/Group"
import QAItem from "../components/qaItem"
import SEO from "../components/seo"
import LinkMailToSupport from "../components/LinkMailToSupport"
import ProjectName from "../components/ProjectName"

const RC = Routing.FAQ.sub
const title = Routing.FAQ.title

const FaqPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      <Group id={getId(RC.GENERAL_TRADING)}>
        <h2>{getTitle(RC.GENERAL_TRADING)}</h2>
        <QAItem>
          <p>What is our commission rate?</p>
          <p>
            Our commission rate starts at $0.02 per unit. We reserve the right
            to change these rates periodically.
          </p>
        </QAItem>

        <QAItem>
          <p>Is commission charged on individual trades?</p>
          <p>• Yes</p>
        </QAItem>
        <QAItem>
          <p>What currencies can I place my order in?</p>
          <p>
            • Deposits can be made of US Dollars and orders are priced
            exclusively in US Dollars.
          </p>
        </QAItem>
        <QAItem>
          <p>What are the minimum and maximum order sizes I can place?</p>
          <p>• One unit minimum, no maximum bet.</p>
        </QAItem>
        <QAItem>
          <p>What time zone are events displayed in?</p>
          <p>• Based on the settings of your device(s)</p>
        </QAItem>
        <QAItem>
          <p>Can I place order(s) while the event is going on?</p>
          <p>
            • Yes, use our Active Player Pro platform to trade your units on our
            live exchange.
          </p>
        </QAItem>
        <QAItem>
          <p>What is an open order?</p>
          <p>• An “open order” is unmatched order sent to the marketplace.</p>
        </QAItem>
        <QAItem>
          <p>Do you offer starting price?</p>
          <p>
            • No, due to legal requirements we do not, the <ProjectName />{" "}
            exchange is peer-to-peer.
          </p>
        </QAItem>
        <QAItem>
          <p>Can I place multiple orders?</p>
          <p>• Yes</p>
        </QAItem>
        <QAItem>
          <p>My order is partially matched what does this mean?</p>
          <p>
            • Partially matched is the same as partially executed and this means
            that portion of your order is still open and available for matching
            on the marketplace.
          </p>
        </QAItem>
        <QAItem>
          <p>How do I know if my order has been fully matched?</p>
          <p>
            • It will appear in “My Positions” and no longer in “Open Orders”.
          </p>
        </QAItem>
        <QAItem>
          <p>Why was my bet canceled (market suspended)?</p>
          <p>
            • Several reason, some of them are interrupted, delayed or postponed
            event. Please contact <LinkMailToSupport />.
          </p>
        </QAItem>
        <QAItem>
          <p>Can I cancel my order?</p>
          <p>• Yes, if the order is still open and not executed.</p>
        </QAItem>
        <QAItem>
          <p>How can I manually cash out or trade out of my units?</p>
          <p>• By selling your units on the live Active Player Pro platform.</p>
        </QAItem>
        <QAItem>
          <p>How long does it take to settle a trade?</p>
          <p>• As soon as the official results are in.</p>
        </QAItem>
        <QAItem>
          <p>
            The event or game I have bet on has been postponed or abandoned will
            I get my money back and when will it be refunded back into my
            account?
          </p>
          <p>
            • As soon as we get the official confirmation of that event being
            postponed or canceled.
          </p>
        </QAItem>
        <QAItem>
          <p>My state has changed from what I originally placed.</p>
          <p>• It has to match the funds availability in the account.</p>
        </QAItem>
        <QAItem>
          <p>
            The market only displays “Place Now” instead of prices. Why is this?
          </p>
          <p>
            • Because, there are no other offers or bids on the marketplace. You
            are welcome to establish Your Own Limit Order.
          </p>
        </QAItem>
        <QAItem>
          <p>There is no money/liquidity in the market.</p>
          <p>
            • If this occurs, there may be little or no activity on the exchange
            and you should check back at a later time.
          </p>
        </QAItem>
        <QAItem>
          <p>I am looking for sport specific rules, where can I find these?</p>
          <p>• In the Rules Section.</p>
        </QAItem>
      </Group>

      <Group id={getId(RC.PAYMENT_METHODS)}>
        <h2>{getTitle(RC.PAYMENT_METHODS)}</h2>

        <QAItem>
          <p>What payment methods can I use?</p>
          <p>• Bank Transfer (AHC), Visa, Master Card, Discover, PayPal.</p>
        </QAItem>
        <QAItem>
          <p>How and where do I make a deposit?</p>
          <p>
            • You can directly deposit from your bank account or card to our
            secured site.
          </p>
        </QAItem>
        <QAItem>
          <p>How and where do I make a withdrawal?</p>
          <p>• Simply go to withdrawals page and process it.</p>
        </QAItem>
        <QAItem>
          <p>How do I withdraw to Skrill or Neteller?</p>
          <p>• One contract minimum, no maximum.</p>
        </QAItem>
        <QAItem>
          <p>What time zone are events displayed in?</p>
          <p>
            • The time zone of each event is predicated on which event is going
            on. The start time to each event will be listed on our platform.
          </p>
        </QAItem>
        <QAItem>
          <p>
            How long do deposits take to reach my <ProjectName /> account?
          </p>
          <p>
            • Information regarding deposits can be found on our Deposit Page.
            The time it takes to deposit money from your <ProjectName /> account
            does not entirely depend on us, it also depends on your payment
            provider and financial institutions.
          </p>
        </QAItem>
        <QAItem>
          <p>
            How long do withdrawals take to reach my <ProjectName /> account?
          </p>
          <p>
            • Information regarding withdrawals can be found on our Deposit
            Page. The time it takes to withdraw money from your <ProjectName />
            account does not entirely depend on us, it also depends on your
            payment provider and financial institutions.
          </p>
        </QAItem>
        <QAItem>
          <p>How can I update my card details or add a new card?</p>
          <p>
            • Simply go to our Deposit Page and process your new payment
            information.
          </p>
        </QAItem>
        <QAItem>
          <p>How do I remove a card from my account?</p>
          <p>
            • You can remove your card on the deposits page. The instructions
            will be listed.
          </p>
        </QAItem>
        <QAItem>
          <p>
            I have not received all my deposit? Was there a fee on the
            transaction?
          </p>
          <p>
            • Perhaps, the detailed information is displayed on our
            Deposit/Withdrawal Page.
          </p>
        </QAItem>
        <QAItem>
          <p>What is the minimum amount I can deposit or withdraw?</p>
          <p>• $10 US Dollars.</p>
        </QAItem>
        <QAItem>
          <p>What is the maximum amount I can deposit or withdraw?</p>
          <p>• There is no maximum.</p>
        </QAItem>
        <QAItem>
          <p>Can I deposit or withdraw with a bank transfer?</p>
          <p>• Yes.</p>
        </QAItem>
      </Group>

      <Group id={getId(RC.ACCOUNT)}>
        <h2>{getTitle(RC.ACCOUNT)}</h2>

        <QAItem>
          <p>
            Can I open an account with <ProjectName /> in my country?
          </p>
          <p>
            • Only in countries where <ProjectName company />
            ’s gaming platform is legal.
          </p>
        </QAItem>
        <QAItem>
          <p>
            Can I have more than one account with <ProjectName />?
          </p>
          <p>• No. One account per individual is strictly enforced.</p>
        </QAItem>
        <QAItem>
          <p>How do I change my account password?</p>
          <p>• Go to account settings and select to change.</p>
        </QAItem>
        <QAItem>
          <p>I would like to change my email address, how can I do this?</p>
          <p>• You can do so in Settings</p>
        </QAItem>
        <QAItem>
          <p>
            Can someone at the same address register a <ProjectName /> Account
          </p>
          <p>• Yes, as long as it’s a different user.</p>
        </QAItem>
        <QAItem>
          <p>I’ve forgotten my sign in details, help!</p>
          <p>• Go to login page and request new credentials.</p>
        </QAItem>
        <QAItem>
          <p>
            I’m not receiving any emails to my registered email address. Are
            there any reasons why?
          </p>
          <p>
            • Check the spam folder in the inbox of your email address you
            provided to <ProjectName company />. Make sure our company is not
            marked as Spam.
          </p>
        </QAItem>
        <QAItem>
          <p>
            I don’t want to receive emails from <ProjectName />.
          </p>
          <p>• Go to settings and unsubscribe.</p>
        </QAItem>
        <QAItem>
          <p>
            Can I change the time zone of my <ProjectName /> account?
          </p>
          <p>
            • It is defaulted to your device time zone, but you can change this
            in settings.
          </p>
        </QAItem>
        <QAItem>
          <p>My account has been suspended.</p>
          <p>
            • Perhaps, there was no activity for a prolonged period of time, or
            for a violation of our Terms and Conditions. Please contact us.
          </p>
        </QAItem>
        <QAItem>
          <p>I wish to close my account</p>
          <p>• Go to settings and close it.</p>
        </QAItem>
        <QAItem>
          <p>
            Does <ProjectName url /> use cookies?
          </p>
          <p>
            • Yes, to ensure that the site is working properly. We will not
            share any information without your consent.
          </p>
        </QAItem>
      </Group>

      <Group id={getId(RC.ACCOUNT_VERIFICATION)}>
        <h2>{getTitle(RC.ACCOUNT_VERIFICATION)}</h2>

        <QAItem>
          <p>I cannot verify my email address!</p>
          <p>
            • When you signed up you should have received a welcome email with a
            verification link, please follow this link to verify the email
            address. If this verification process does not work, please logout
            of all devices, before reattempting to use the verification link
            again. If the problem persists please email us at{" "}
            <LinkMailToSupport />
          </p>
        </QAItem>
        <QAItem>
          <p>Why do I need to verify my account?</p>
          <p>
            • In order for us to comply with regulatory requirements; it is
            necessary to verify personal details upon deposition in a{" "}
            <ProjectName />
            account. We try and complete these verification checks
            electronically but sometimes we will require users to send a copy of
            their documents to verify their age and address.
          </p>
        </QAItem>
        <QAItem>
          <p>How can I provide you with these documents?</p>
          <p>
            • You can upload your documents on our file upload page or sent them{" "}
            <LinkMailToSupport />
          </p>
        </QAItem>
        <QAItem>
          <p>
            Why can I not use a Driver’s License or Passport as proof of
            address?
          </p>
          <p>
            • Passport, Driver’s license with photo, National Identity card,
            Firearms or shotgun license. You can use the following documents as
            proof of address: Bank, Credit Card or Credit Union statement (dated
            within the last 3 months), Utility bill (dated within the last 3
            months). Please Note: we cannot accept mobile phone bills or
            e-statement documents as proof of address.
          </p>
        </QAItem>
        <QAItem>
          <p>
            How long does it take to verify my account once I have sent my
            documents?
          </p>
          <p>
            • Account verification will normally be completed within 24 hours of
            us receiving the required documents. If you haven’t received an
            email regarding your account verification within 24 hours, please
            contact our customer support team.
          </p>
        </QAItem>
        <QAItem>
          <p>
            Does <ProjectName /> use electronic verification?
          </p>
          <p>
            • Where possible, <ProjectName /> exchange will try and verify your
            user account electronically. <ProjectName /> exchange will run your
            details against a number of official databases - this will not leave
            a trace on credit files and won’t affect your credit rating. If your
            account cannot be verified electronically, you can verify your
            account by sending a copy of a photo ID and proof of address to{" "}
            <LinkMailToSupport />
          </p>
        </QAItem>
      </Group>

      <Group id={getId(RC.MISCELLANEOUS)}>
        <h2>{getTitle(RC.MISCELLANEOUS)}</h2>

        <QAItem>
          <p>
            • Can I access <ProjectName /> Exchanges desktop site on my phone?
          </p>
          <p>• Yes</p>
        </QAItem>
        <QAItem>
          <p>
            • Does <ProjectName /> Exchange have an API to which I can connect?
          </p>
          <p>
            • We have one coming very soon. We are diligently working on it.
          </p>
        </QAItem>
        <QAItem>
          <p>• Can I get access to a data feed of your prices?</p>
          <p>
            • In the future, Yes. Now it is available for users that are
            subscribed to it by case per case basis.
          </p>
        </QAItem>
        <QAItem>
          <p>
            • I can’t connect to the live servers/I’m having connection
            problems.
          </p>
          <p>
            • Please check Your internet connection. We may be are undergoing
            some routine maintenance.
          </p>
        </QAItem>
        <QAItem>
          <p>
            • Do you have a Twitter, Facebook or social media account I can
            follow?
          </p>
          <p>
            • Yes, simply click on icons that are on the footer of our site.
            Check out our latest promotions!
          </p>
        </QAItem>
      </Group>

      <Group id={getId(RC.ACCOUNT_SUMMARY)}>
        <h2>{getTitle(RC.ACCOUNT_SUMMARY)}</h2>
        <QAItem>
          <p>I cannot see my previous orders in the account summary</p>
          <p>
            • You can view your order history by inputting the relevant date
            range in the “Filter Activity” section of your account page.
          </p>
        </QAItem>
        <QAItem>
          <p>
            I want to only see my deposits, withdrawals or both. How can I do
            this?
          </p>
          <p>• You can filter your settings.</p>
        </QAItem>
        <QAItem>
          <p>Is it possible to show the commission I pay on each trade?</p>
          <p>• Yes</p>
        </QAItem>
        <QAItem>
          <p>How do I see history older than last year?</p>
          <p>
            • You can view your bet history by inputting the relevant date range
            in the “Filter Activity” section of your account page.
          </p>
        </QAItem>
        <QAItem>
          <p>Where can I see my total liability, potential loss or risk?</p>
          <p>
            - You can view your current exposure in the account summary on the
            top of the page.
          </p>
        </QAItem>
      </Group>
    </Layout>
  )
}

export default FaqPage
